// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-free-estimates-js": () => import("./../../../src/pages/free-estimates.js" /* webpackChunkName: "component---src-pages-free-estimates-js" */),
  "component---src-pages-garage-door-openers-js": () => import("./../../../src/pages/garage-door-openers.js" /* webpackChunkName: "component---src-pages-garage-door-openers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-replacement-js": () => import("./../../../src/pages/replacement.js" /* webpackChunkName: "component---src-pages-replacement-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

